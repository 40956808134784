<template>
  <div>
      <!-- <div class="pageButton">
        <div>
          <el-button size="mini" @click="changePage(0)" round>上一页</el-button>
          <span class="margin-left-10 margin-right-10"> {{currentPage}} / {{pageCount}} </span>
          <el-button size="mini" @click="changePage(1)" round>下一页</el-button>
        </div>
      </div>
      <pdf v-loading="loading"
            element-loading-text="使用文档加载中..." style="width:80vh;height: 100vh;margin:auto"  :src="pdfFile"
              :page="currentPage"
              @num-pages="pageCount=$event"
              @page-loaded="currentPage=$event"
              @loaded="loadPdf">
      </pdf> -->
  </div>
</template>

<script>
// import pdf from 'vue-pdf'
import file from '@/assets/滨化价格管理平台使用说明书.pdf'
export default {
  components:{
    // pdf
  },
  name: "document",
  data() {//数据
    return {
      pdfFile: '',
      // pageNum:[],
      // currentPage: 0, // 页码
      // pageCount: 0, // 总页数
      // loading:true,
    };
  },
  created() {//实例初始化之前的方法
    this.pdfFile=file
    window.location.href=this.pdfFile
  },
  mounted() {//实例挂载之前的方法

  },
  methods: {//vue实例的方法
    // 翻页
    // changePage (val) {
    //   if (val === 0 && this.currentPage > 1) {
    //     this.currentPage --;
    //   }
    //   if (val === 1 && this.currentPage < this.pageCount) {
    //     this.currentPage ++;
    //   }
    // },
    // pdf加载时
    // loadPdf () {
    //   this.currentPage = 1 // 加载的时候先加载第一页
    //   this.loading=false;
    // },
  },
}
</script>

<style lang="scss" scoped>
// .pageButton{
//   position: fixed;
//   left: 0;
//   right: 0;
//   top: 0;
//   z-index: 999;
//   @include themeify{background:themed('mask')!important;};
// }
// .pageButton>div{
//   height: 40px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   @include themeify{background:themed('mask')!important;};
// }
</style>
